import axios from 'axios'
import store from '@/store'
import router from '@/router'
import config from '@/config'
import { generateSign } from "@/utils/tools"
import isNaN from "lodash.isnan"
import Message from "@/components/library/Message";
export const baseURL = process.env.NODE_ENV === 'development' ? config.baseUrl.development : config.baseUrl.production

const instance = axios.create({
  baseURL,
  timeout: 5000
})

instance.interceptors.request.use(config => {
  if (store.state.player.token) {
    config.headers.Authorization = `${store.state.player.token}`
  }
  config.params = Object.assign({}, config.params, {
    timestamp: Math.round(new Date() / 1000)
  });
  // 参數全局签名
  if (config.params) {
    if (isNaN(config.data)) {
      config.data = {};
    }
    let sign = generateSign(Object.assign({
      ...config.params
    }, {
      ...config.data
    }));
    config.params.sign = sign;
  }
  return config
}, err => {
  return Promise.reject(err)
})

// res => res.data  取出data數據，将来调用接口的時候直接拿到的就是后台的數據
instance.interceptors.response.use(res => res.data, err => {
  if (err.response && err.response.status === 401) {
    Message({ type: "error", text: err.response.data.msg || "授权失败,请重新登录"});
    store.dispatch('player/playInfoAction', {})
    store.dispatch("player/authTokenAction", "");
    const fullPath = encodeURIComponent(router.currentRoute.value.fullPath)
    router.push('/login?redirectUrl=' + fullPath)
  }
  return Promise.reject(err)
})

// 请求工具函數
export default (url, method, submitData) => {
  return instance({
    url,
    method,
    [method.toLowerCase() === 'get' ? 'params' : 'data']: submitData
  })
}
