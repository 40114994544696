/**
 * 正常下註项
 */
export const balls = [
    { ball: "01", color: "red", element: "金", zodiac: "牛", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },//
    { ball: "02", color: "red", element: "木", zodiac: "鼠", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },//
    { ball: "03", color: "blue", element: "水", zodiac: "猪", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },//
    { ball: "04", color: "blue", element: "火", zodiac: "狗", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },//
    { ball: "05", color: "green", element: "土", zodiac: "鸡", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },//
    { ball: "06", color: "green", element: "金", zodiac: "猴", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "07", color: "red", element: "木", zodiac: "羊", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "08", color: "red", element: "水", zodiac: "羊", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "09", color: "blue", element: "火", zodiac: "蛇", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "10", color: "blue", element: "土", zodiac: "龙", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "11", color: "green", element: "金", zodiac: "龙", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "12", color: "red", element: "木", zodiac: "兔", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "13", color: "red", element: "水", zodiac: "兔", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "14", color: "blue", element: "火", zodiac: "虎", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "15", color: "blue", element: "土", zodiac: "鼠", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "16", color: "green", element: "金", zodiac: "猪", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "17", color: "green", element: "木", zodiac: "狗", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "18", color: "red", element: "水", zodiac: "鸡", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "19", color: "red", element: "火", zodiac: "猴", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "20", color: "blue", element: "土", zodiac: "羊", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "21", color: "green", element: "金", zodiac: "马", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "22", color: "green", element: "木", zodiac: "蛇", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "23", color: "red", element: "水", zodiac: "龙", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "24", color: "red", element: "火", zodiac: "兔", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "25", color: "blue", element: "土", zodiac: "虎", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "26", color: "blue", element: "金", zodiac: "牛", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "27", color: "green", element: "木", zodiac: "鼠", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "28", color: "green", element: "水", zodiac: "猪", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "29", color: "red", element: "火", zodiac: "狗", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "30", color: "red", element: "土", zodiac: "鸡", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "31", color: "blue", element: "金", zodiac: "猴", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "32", color: "green", element: "木", zodiac: "羊", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "33", color: "green", element: "水", zodiac: "马", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "34", color: "red", element: "火", zodiac: "蛇", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "35", color: "red", element: "土", zodiac: "龙", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "36", color: "blue", element: "金", zodiac: "兔", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "37", color: "blue", element: "木", zodiac: "虎", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "38", color: "green", element: "水", zodiac: "牛", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "39", color: "green", element: "火", zodiac: "鼠", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "40", color: "red", element: "土", zodiac: "猪", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "41", color: "blue", element: "金", zodiac: "狗", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "42", color: "blue", element: "木", zodiac: "鸡", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "43", color: "green", element: "水", zodiac: "猴", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "44", color: "green", element: "火", zodiac: "羊", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "45", color: "red", element: "土", zodiac: "马", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "46", color: "red", element: "金", zodiac: "蛇", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "47", color: "blue", element: "木", zodiac: "龙", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "48", color: "blue", element: "水", zodiac: "兔", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
    { ball: "49", color: "green", element: "火", zodiac: "虎", value: "", checked: false, odds: null, double: false, special: 1, min: 1, max: 100 },
];
/**
 * 生肖下註项
 */
export const zodiacs = [

]
/**
 * 尾數下註项
 */
export const tails = [
    { ball: "1尾", text: "01,11,21,31,41", checked: false, value: "", odds: null, double: false, min: 1, max: 100 },
    { ball: "2尾", text: "02,12,22,32,42", checked: false, value: "", odds: null, double: false, min: 1, max: 100 },
    { ball: "3尾", text: "03,13,23,33,43", checked: false, value: "", odds: null, double: false, min: 1, max: 100 },
    { ball: "4尾", text: "04,14,24,34,44", checked: false, value: "", odds: null, double: false, min: 1, max: 100 },
    { ball: "5尾", text: "05,15,25,35,45", checked: false, value: "", odds: null, double: false, min: 1, max: 100 },
    { ball: "6尾", text: "06,16,26,36,46", checked: false, value: "", odds: null, double: false, min: 1, max: 100 },
    { ball: "7尾", text: "07,17,27,37,47", checked: false, value: "", odds: null, double: false, min: 1, max: 100 },
    { ball: "8尾", text: "08,18,28,38,48", checked: false, value: "", odds: null, double: false, min: 1, max: 100 },
    { ball: "9尾", text: "09,19,29,39,49", checked: false, value: "", odds: null, double: false, min: 1, max: 100 },
    { ball: "0尾", text: "10,20,30,40", checked: false, value: "", odds: null, double: false, min: 1, max: 100 },
]
/**
 * 五行下註项
 */
export const elements = [
    { ball: "金", text: "01,06,11,16,21,26,31,36,41,46", checked: false, value: "", odds: null, double: false, min: 1, max: 100 },
    { ball: "木", text: "02,07,12,17,22,27,32,37,42,47", checked: false, value: "", odds: null, double: false, min: 1, max: 100 },
    { ball: "水", text: "03,08,13,18,23,28,33,38,43,48", checked: false, value: "", odds: null, double: false, min: 1, max: 100 },
    { ball: "火", text: "04,09,14,19,24,29,34,39,44,49", checked: false, value: "", odds: null, double: false, min: 1, max: 100 },
    { ball: "土", text: "05,10,15,20,25,30,35,40,45", checked: false, value: "", odds: null, double: false, min: 1, max: 100 },
]

/**
 * 半波下註项
 */
export const halfWaves = [
    // { ball: "洪波", text: "01,02,07,08,12,13,18,19,23,24,29,30,34,35,40,45,46", checked: false, value: "" },
    { ball: "紅單", text: "01,07,13,19,23,29,35,45", checked: false, value: "", odds: null, min: 1, max: 100 },
    { ball: "紅双", text: "02,08,12,18,24,30,34,40,46", checked: false, value: "", odds: null, min: 1, max: 100 },
    { ball: "紅大", text: "29,30,34,35,40,45,46", checked: false, value: "", odds: null, min: 1, max: 100 },
    { ball: "紅小", text: "01,02,07,08,12,13,18,19,23,24", checked: false, value: "", odds: null, min: 1, max: 100 },
    // { ball: "藍波", text: "03,04,09,10,14,15,20,25,26,31,36,37,41,42,47,48", checked: false, value: "" },
    { ball: "藍單", text: "03,09,15,25,31,37,41,47", checked: false, value: "", odds: null, min: 1, max: 100 },
    { ball: "藍双", text: "04,10,14,20,26,36,42,48", checked: false, value: "", odds: null, min: 1, max: 100 },
    { ball: "藍大", text: "25,26,31,36,37,41,42,47,48", checked: false, value: "", odds: null, min: 1, max: 100 },
    { ball: "藍小", text: "03,04,09,10,14,15,20", checked: false, value: "", odds: null, min: 1, max: 100 },
    // { ball: "綠波", text: "05,06,11,16,17,21,22,27,28,32,33,38,39,43,44,49", checked: false, value: "" },
    { ball: "綠單", text: "05,11,17,21,27,33,39,43,49", checked: false, value: "", odds: null, min: 1, max: 100 },
    { ball: "綠双", text: "06,16,22,28,32,38,44", checked: false, value: "", odds: null, min: 1, max: 100 },
    { ball: "綠大", text: "27,28,32,33,38,39,43,44,49", checked: false, value: "", odds: null, min: 1, max: 100 },
    { ball: "綠小", text: "05,06,11,16,17,21,22", checked: false, value: "", odds: null, min: 1, max: 100 },
]

/**
 * 特碼特殊下註项
 */
export const extTemaNumber = [
    { ball: "單", text: "特單", value: "", odds: null, special: 100, min: 1, max: 100 },
    { ball: "双", text: "特双", value: "", odds: null, special: 100, min: 1, max: 100 },
    { ball: "大", text: "特大", value: "", odds: null, special: 100, min: 1, max: 100 },
    { ball: "小", text: "特小", value: "", odds: null, special: 100, min: 1, max: 100 },
    { ball: "合單", text: "合單", value: "", odds: null, special: 100, min: 1, max: 100 },
    { ball: "合双", text: "合双", value: "", odds: null, special: 100, min: 1, max: 100 },
    { ball: "家禽", text: "家禽", value: "", odds: null, special: 100, min: 1, max: 100 },
    { ball: "野兽", text: "野兽", value: "", odds: null, special: 100, min: 1, max: 100 },
    { ball: "紅波", text: "紅波", value: "", odds: null, color: "red", special: 101, min: 1, max: 100 },
    { ball: "藍波", text: "藍波", value: "", odds: null, color: "blue", special: 101, min: 1, max: 100 },
    { ball: "綠波", text: "綠波", value: "", odds: null, color: "green", special: 101, min: 1, max: 100 },
    { ball: "尾大", text: "尾大", value: "", odds: null, special: 100, min: 1, max: 100 },
    { ball: "尾小", text: "尾小", value: "", odds: null, special: 100, min: 1, max: 100 },
    { ball: "合大", text: "合大", value: "", odds: null, special: 100, min: 1, max: 100 },
    { ball: "合小", text: "合小", value: "", odds: null, special: 100, min: 1, max: 100 },
];
/**
 * 正碼特殊下註项
 */
export const extZhengmaNumber = [
    { ball: "總單", text: "總單", value: "", odds: null, special: 100, min: 1, max: 100 },
    { ball: "總双", text: "總双", value: "", odds: null, special: 100, min: 1, max: 100 },
    { ball: "總大", text: "總大", value: "", odds: null, special: 100, min: 1, max: 100 },
    { ball: "總小", text: "總小", value: "", odds: null, special: 100, min: 1, max: 100 },
];
/**
* 正特碼特殊下註项 正特碼 text 拼接 1-單
*/
export const extZhengtemaNumber = [
    { ball: "單", text: "單", value: "", odds: null, special: 100, min: 1, max: 100 },
    { ball: "双", text: "双", value: "", odds: null, special: 100, min: 1, max: 100 },
    { ball: "大", text: "大", value: "", odds: null, special: 100, min: 1, max: 100 },
    { ball: "小", text: "小", value: "", odds: null, special: 100, min: 1, max: 100 },
    { ball: "合單", text: "合單", value: "", odds: null, special: 100, min: 1, max: 100 },
    { ball: "合双", text: "合双", value: "", odds: null, special: 100, min: 1, max: 100 },
    { ball: "合大", text: "合大", value: "", odds: null, special: 100, min: 1, max: 100 },
    { ball: "合小", text: "合小", value: "", odds: null, special: 100, min: 1, max: 100 },
    { ball: "紅", text: "紅", value: "", odds: null, color: "red", special: 101, min: 1, max: 100 },
    { ball: "藍", text: "藍", value: "", odds: null, color: "blue", special: 101, min: 1, max: 100 },
    { ball: "綠", text: "綠", value: "", odds: null, color: "green", special: 101, min: 1, max: 100 },
    { ball: "尾大", text: "尾大", value: "", odds: null, special: 100, min: 1, max: 100 },
    { ball: "尾小", text: "尾小", value: "", odds: null, special: 100, min: 1, max: 100 },
];

/**
 * 生肖量下註项
 */
export const extZodiacs = [
    { ball: "二肖", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "三肖", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "四肖", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "五肖", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "六肖", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "七肖", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
];

/**
 * 尾數扩展下註项
 */
export const extTails = [
    { ball: "2尾", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "3尾", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "4尾", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "5尾", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "6尾", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "7尾", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
];

/**
 * 七吗下註项
 */
export const qima = [
    { ball: "單0", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "單1", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "單2", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "單3", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "單4", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "單5", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "單6", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "單7", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },

    { ball: "双0", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "双1", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "双2", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "双3", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "双4", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "双5", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "双6", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "双7", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },

    { ball: "大0", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "大1", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "大2", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "大3", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "大4", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "大5", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "大6", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "大7", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },

    { ball: "小0", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "小1", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "小2", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "小3", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "小4", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "小5", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "小6", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
    { ball: "小7", text: "", value: "", odds: null, checked: false, min: 1, max: 100 },
];

/**
 * 玩法集合
 */
export const playInfo = [
    { key: "tema", list: [{ value: 1, label: "特碼A盤" }, { value: 2, label: "特碼B盤" }] },
    { key: "zhengma", list: [{ value: 3, label: "正碼A盤" }, { value: 4, label: "正碼B盤" }] },
    {
        key: "zhengtema", list: [{ value: 5, label: "正1特" },
        { value: 6, label: "正2特" },
        { value: 7, label: "正3特" },
        { value: 8, label: "正4特" },
        { value: 9, label: "正5特" },
        { value: 10, label: "正6特" },]
    },
    {
        key: "lianma", list: [{ value: 11, label: "二全中", combined: 2 },
        { value: 12, label: "二中特", combined: 2 },
        { value: 13, label: "特串", combined: 2 },
        { value: 14, label: "三全中", combined: 3 },
        { value: 15, label: "三中二", combined: 3 },
        { value: 16, label: "三中二II", combined: 3 }]
    },
    {
        key: "temaxiangmu", list: [{ value: 17, label: "特肖" },
        { value: 18, label: "半波" },
        { value: 19, label: "五行" }]
    },
    {
        key: "shengxiaoxiangmu", list: [{ value: 20, label: "一肖" },
        { value: 21, label: "一肖不中" },
        { value: 22, label: "生肖量" }]
    },
    {
        key: "zongshuxiangmu", list: [{ value: 23, label: "尾數" },
        { value: 24, label: "尾數不中" },
        { value: 25, label: "七碼" },
        { value: 26, label: "尾數量" }]
    },
    {
        key: "liuxiao", list: [{ value: 27, label: "中獎" },
        { value: 28, label: "不中" }]
    },
    {
        key: "duoxuanbuzhong", list: [{ value: 29, label: "五不中", combined: 5 },
        { value: 30, label: "六不中", combined: 6 },
        { value: 31, label: "七不中", combined: 7 },
        { value: 32, label: "八不中", combined: 8 },
        { value: 33, label: "九不中", combined: 9 },
        { value: 34, label: "十不中", combined: 10 }]
    },
    {
        key: "shengxiaolian", list: [{ value: 35, label: "二肖中", combined: 2 },
        { value: 36, label: "三肖中", combined: 3 },
        { value: 37, label: "四肖中", combined: 4 },
        { value: 38, label: "五肖中", combined: 5 },
        { value: 39, label: "二肖不中", combined: 2 },
        { value: 40, label: "三肖不中", combined: 3 },
        { value: 41, label: "四肖不中", combined: 4 },
        { value: 42, label: "五肖不中", combined: 5 }]
    },
    {
        key: "weishulian", list: [{ value: 43, label: "二尾中", combined: 2 },
        { value: 44, label: "三尾中", combined: 3 },
        { value: 45, label: "四尾中", combined: 4 },
        { value: 46, label: "二尾不中", combined: 2 },
        { value: 47, label: "三尾不中", combined: 3 },
        { value: 48, label: "四尾不中", combined: 4 }]
    },
    {
        key: "duoxuanzhongyi", list: [{ value: 49, label: "五中一", combined: 5 },
        { value: 50, label: "六中一", combined: 6 },
        { value: 51, label: "七中一", combined: 7 },
        { value: 52, label: "八中一", combined: 8 },
        { value: 53, label: "九中一", combined: 9 },
        { value: 54, label: "十中一", combined: 10 }]
    },
    {
        key: "tepingzhong", list: [{ value: 55, label: "一粒任中", combined: 1 },
        { value: 56, label: "二粒任中", combined: 2 },
        { value: 57, label: "三粒任中", combined: 3 },
        { value: 58, label: "四粒任中", combined: 4 },
        { value: 59, label: "五粒任中", combined: 5 }]
    }
];
/**
 * 游戏玩法集合 六合彩/3D
 */
export const gameInfo = [{
    type: "liuhecai",
    plays: [
        { path: "/liuhecai", name: "tema", title: "特碼", id: 0 },
        { path: "/liuhecai/zhengma", name: "zhengma", title: "正碼", id: 1 },
        { path: "/liuhecai/zhengtema", name: "zhengtema", title: "正特碼", id: 2 },
        { path: "/liuhecai/lianma", name: "lianma", title: "連碼", id: 3 },
        { path: "/liuhecai/temaxiangmu", name: "temaxiangmu", title: "特碼項目", id: 4 },
        { path: "/liuhecai/shengxiaoxiangmu", name: "shengxiaoxiangmu", title: "生肖項目", id: 5 },
        { path: "/liuhecai/zongshuxiangmu", name: "zongshuxiangmu", title: "總數項目", id: 6 },
        { path: "/liuhecai/liuxiao", name: "liuxiao", title: "六肖", id: 7 },
        { path: "/liuhecai/duoxuanbuzhong", name: "duoxuanbuzhong", title: "多選不中", id: 8 },
        { path: "/liuhecai/shengxiaolian", name: "shengxiaolian", title: "生肖連", id: 9 },
        { path: "/liuhecai/weishulian", name: "weishulian", title: "尾數連", id: 10 },
        { path: "/liuhecai/duoxuanzhongyi", name: "duoxuanzhongyi", title: "多選中一", id: 11 },
        { path: "/liuhecai/tepingzhong", name: "tepingzhong", title: "特平中", id: 12 },
    ]
}, {
    type: "futicai",
    plays: [
        { path: "/futicai", name: "shuangmianpankou", title: "雙面盤口", id: 0 },
        { path: "/futicai/yizizuhe", name: "yizizuhe", title: "一字組合", id: 1 },
        { path: "/futicai/yizidingwei", name: "yizidingwei", title: "一字定位", id: 2 },
        { path: "/futicai/erzizuhe", name: "erzizuhe", title: "二字組合", id: 3 },
        { path: "/futicai/erzidingwei", name: "erzidingwei", title: "二字定位", id: 4 },
        { path: "/futicai/erziheshu", name: "erziheshu", title: "二字和數", id: 5 },
        { path: "/futicai/sanzizuhe", name: "sanzizuhe", title: "三字組合", id: 6 },
        { path: "/futicai/sanzidingwei", name: "sanzidingwei", title: "三字定位", id: 7 },
        { path: "/futicai/sanziheshu", name: "sanziheshu", title: "三字和數", id: 8 },
        { path: "/futicai/zuxuansan", name: "zuxuansan", title: "組選三", id: 9 },
        { path: "/futicai/zuxuanliu", name: "zuxuanliu", title: "組選六", id: 10 },
        // { path: "/futicai/fushizuhe", name: "fushizuhe", title: "複式組合", id: 11 },
        { path: "/futicai/yiziguoguan", name: "yiziguoguan", title: "一字過關", id: 12 },
        { path: "/futicai/kuadu", name: "kuadu", title: "跨度", id: 13 },
        { path: "/futicai/kuaixuanpan", name: "kuaixuanpan", title: "快選盤", id: 14 },
        { path: "/futicai/qita", name: "qita", title: "其他", id: 15 },]
}];


export const fushiNumbers = [
    {
        ball: "0",
        checked: false,
        tabType: 0,
    },
    {
        ball: "1",
        checked: false,
        tabType: 0,
    },
    {
        ball: "2",
        checked: false,
        tabType: 0,
    },
    {
        ball: "3",
        checked: false,
        tabType: 0,
    },
    {
        ball: "4",
        checked: false,
        tabType: 0,
    },
    {
        ball: "5",
        checked: false,
        tabType: 0,
    },
    {
        ball: "6",
        checked: false,
        tabType: 0,
    },
    {
        ball: "7",
        checked: false,
        tabType: 0,
    },
    {
        ball: "8",
        checked: false,
        tabType: 0,
    },
    {
        ball: "9",
        checked: false,
        tabType: 0,
    },
    {
        ball: "0",
        checked: false,
        tabType: 1,
    },
    {
        ball: "1",
        checked: false,
        tabType: 1,
    },
    {
        ball: "2",
        checked: false,
        tabType: 1,
    },
    {
        ball: "3",
        checked: false,
        tabType: 1,
    },
    {
        ball: "4",
        checked: false,
        tabType: 1,
    },
    {
        ball: "5",
        checked: false,
        tabType: 1,
    },
    {
        ball: "6",
        checked: false,
        tabType: 1,
    },
    {
        ball: "7",
        checked: false,
        tabType: 1,
    },
    {
        ball: "8",
        checked: false,
        tabType: 1,
    },
    {
        ball: "9",
        checked: false,
        tabType: 1,
    },
    {
        ball: "0",
        checked: false,
        tabType: 2,
    },
    {
        ball: "1",
        checked: false,
        tabType: 2,
    },
    {
        ball: "2",
        checked: false,
        tabType: 2,
    },
    {
        ball: "3",
        checked: false,
        tabType: 2,
    },
    {
        ball: "4",
        checked: false,
        tabType: 2,
    },
    {
        ball: "5",
        checked: false,
        tabType: 2,
    },
    {
        ball: "6",
        checked: false,
        tabType: 2,
    },
    {
        ball: "7",
        checked: false,
        tabType: 2,
    },
    {
        ball: "8",
        checked: false,
        tabType: 2,
    },
    {
        ball: "9",
        checked: false,
        tabType: 2,
    },
];