import {
    requestLastBets,
    requestBetType,
    requestGameInfo,
    requestGameOddAndFilter,
    requestBetMonthReport,
    requestGameSettleResult,
    requestBetContentSummary,
    requestBetOddsDetail,
    requestScrollNotice,
    requestPageNotice,
    requestDetailNotice
} from '@/api/game'
// 游戏模块
export default {
    namespaced: true,
    state() {
        return {
            config: {},//盤口配置相关
            lastBets: [],//最后下註项
            limit: [],//游戏投註限制配置
            odds: [],//游戏賠率
            baseNumbers: [],
            quickAmount: {
                amount: null,
                list: []
            },//快速投注金額
        }
    },
    mutations: {
        SET_QUICK_AMOUNT(state, amount) {
            state.quickAmount = amount;
        },
        SET_LAST_BETS(state, { bets, request }) {
            if (request) {
                state.lastBets = bets;
            }
            else {
                state.lastBets = bets.concat(state.lastBets).slice(0, 10);
            }
        },
        SET_LIMIT_CONFIG(state, limit) {
            state.limit = limit;
        },
        SET_ODDS_CONFIG(state, odds) {
            state.odds = odds;
        },
        SET_AMOLHC_CONFIG(state, config) {
            // console.log('SET_AMOLHC_CONFIG store', config)
            state.config = config;
        },
        SET_BASE_NUMBERS(state, numbers) {
            state.baseNumbers = numbers;
        }
    },
    actions: {
        // 設置快速下注金額
        setQuickAmountAction({ commit }, amount) {
            commit("SET_QUICK_AMOUNT", amount)
        },
        // 设置基础球號        
        setBaseNumbersAction({ commit }, numbers) {
            commit("SET_BASE_NUMBERS", numbers)
        },
        // 游戏限制        
        setLimitConfigAction({ commit }, bet) {
            commit("SET_LIMIT_CONFIG", bet)
        },
        // 游戏賠率       
        setGameOddsAction({ commit }, odds) {
            commit("SET_ODDS_CONFIG", odds)
        },
        // 游戏开獎相关配置   
        setGameConfigAction({ commit }, config) {
            commit("SET_AMOLHC_CONFIG", config)
        },
        // 接口获取最新註單
        requestLastBetsAction({ commit }, query) {
            return new Promise((resolve, reject) => {
                requestLastBets(query).then(res => {
                    if (res && res.code == 0) {
                        if (res.res.total < 10) {
                            for (let i = 0; i < 10 - res.res.total; i++) {
                                res.res.rows.push(null);
                            }
                        }
                        commit("SET_LAST_BETS", { bets: res.res.rows, request: true })
                    }
                    else {
                        commit("SET_LAST_BETS", { bets: [], request: true })
                    }
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            });
        },
        // 通知公告
        requestScrollNoticeAction({ commit }) {
            return new Promise((resolve, reject) => {
                requestScrollNotice({}).then(res => {
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            });
        },
        // 投註项下拉请求
        requestBetTypeAction({ commit }, data) {
            return new Promise((resolve, reject) => {
                requestBetType(data).then(res => {
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            });
        },
        // 游戏当前期状态
        requestGameInfoAction({ commit }, data) {
            return new Promise((resolve, reject) => {
                requestGameInfo(data).then(res => {
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            });
        },
        // 投註明細下拉请求
        requestBetPageAction({ commit }, query) {
            return new Promise((resolve, reject) => {
                requestLastBets(query).then(res => {
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            });
        },
        // 投註月报表
        requestBetMonthReportAction({ commit }, query) {
            return new Promise((resolve, reject) => {
                requestBetMonthReport(query).then(res => {
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            });
        },
        // 游戏開獎結果
        requestGameSettleResultAction({ commit }, data) {
            return new Promise((resolve, reject) => {
                requestGameSettleResult(data).then(res => {
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            });
        },
        // 投註汇總
        requestBetContentSummaryAction({ commit }, params) {
            return new Promise((resolve, reject) => {
                requestBetContentSummary(params).then(res => {
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            });
        },
        // 游戏賠率及限制
        async requestGameOddAndFilterAction({ commit }, data) {
            return await new Promise((resolve, reject) => {
                requestGameOddAndFilter(data).then(res => {
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            });
        },
        // 復式明细
        async requestBetOddsDetailAction({ commit }, data) {
            return await new Promise((resolve, reject) => {
                requestBetOddsDetail(data).then(res => {
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            });
        },
        // 手动设置        
        lastBetsAction({ commit }, { bets, request }) {
            commit("SET_LAST_BETS", { bets, request })
        },
        //通知公告分页
        requestPageNoticeAction({ commit }, query) {
            return new Promise((resolve, reject) => {
                requestPageNotice(query).then(res => {
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            });
        },
        // 公告明细
        async requestNoticeDetailAction({ commit }, data) {
            return await new Promise((resolve, reject) => {
                requestDetailNotice(data).then(res => {
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            });
        },

    }
}
