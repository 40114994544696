<template>
  <div class="xtx-dialog" v-show="visible" :class="{ fade }">
    <div class="wrapper" :class="{ fade }">
      <div class="header">
        <h3>{{ title }}</h3>
        <a
          @click="closeDialog"
          href="JavaScript:;"
          class="iconfont icon-close-new"
        ></a>
      </div>
      <div class="body">
        <slot />
      </div>
      <div class="footer" v-if="footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
// vue3.0 v-model:visible 语法糖，拆解 （:visible + @update:visible）
// vue2.0 visible.sync 语法糖，拆解 （:visible + @update:visible）
import { ref, watch } from "vue";
export default {
  name: "XtxDialog",
  props: {
    title: {
      type: String,
      default: "",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    footer: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const fade = ref(false);
    // visible的值为true打开對话框，否则就是关闭對话框，其实控制fade的值即可
    watch(
      () => props.visible,
      (newVal) => {
        setTimeout(() => {
          fade.value = newVal;
        }, 0);
      },
      { immediate: true }
    );

    // 自己关闭對话框，修改父組件數據状态
    const closeDialog = () => {
      emit("closeDialog");
    };

    return { fade, closeDialog };
  },
};
</script>

<style scoped lang="less">
.xtx-dialog {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 8887;
  background: rgba(0, 0, 0, 0);
  &.fade {
    transition: all 0.4s;
    background: rgba(0, 0, 0, 0.5);
  }
  .wrapper {
    width: 600px;
    overflow: hidden;
    background: #fff;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    opacity: 0;
    overflow: hidden;
    &.fade {
      transition: all 0.4s;
      transform: translate(-50%, -50%);
      opacity: 1;
    }
    .body {
      padding: 20px 40px;
      font-size: 16px;
      .icon-warning {
        color: @priceColor;
        margin-right: 3px;
        font-size: 16px;
      }
    }
    .footer {
      text-align: center;
      padding: 10px 0 30px 0;
    }
    .header {
      position: relative;
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
      border-bottom: 1px solid #f5f5f5;
      width: inherit !important;
      min-width: inherit;
      box-sizing: border-box;
      h3 {
        font-weight: normal;
        font-size: 18px;
      }
      a {
        position: absolute;
        right: 25px;
        top: 15px;
        font-size: 24px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        color: #999;
        &:hover {
          color: #666;
        }
      }
    }
  }
}
</style>
