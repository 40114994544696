import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'
const Blank = () => import('@/views/blank/index')
const Login = () => import('@/views/login/index')
const Layout = () => import('@/views/Layout')
//六合彩
const LiuHeTeMa = () => import('@/views/liuhecai/tema')
const LiuHeZhengMa = () => import('@/views/liuhecai/zhengma')
const LiuHeZhengTMa = () => import('@/views/liuhecai/zhengtema')
const LiuHeLianMa = () => import('@/views/liuhecai/lianma')
const LiuHeTMXiangmu = () => import('@/views/liuhecai/temaxiangmu')
const LiuHeSXXiangmu = () => import('@/views/liuhecai/shengxiaoxiangmu')
const LiuHeZSXiangmu = () => import('@/views/liuhecai/zongshuxiangmu')
const LiuHeLiuXiao = () => import('@/views/liuhecai/liuxiao')
const LiuHeDXBuzhong = () => import('@/views/liuhecai/duoxuanbuzhong')
const LiuHeSXLian = () => import('@/views/liuhecai/shengxiaolian')
const LiuHeWSLian = () => import('@/views/liuhecai/weishulian')
const LiuHeDXZhongyi = () => import('@/views/liuhecai/duoxuanzhongyi')
const LiuHeTPZhong = () => import('@/views/liuhecai/tepingzhong')
//福/體彩
const FtcShuangMianPanKou = () => import('@/views/futicai/shuangmianpankou')
const FtcErZiDingWei = () => import('@/views/futicai/erzidingwei')
const FtcErZiHeShu = () => import('@/views/futicai/erziheshu')
const FtcErZiZuHe = () => import('@/views/futicai/erzizuhe')
const FtcFuShiZuHe = () => import('@/views/futicai/fushizuhe')
const FtcKuaDu = () => import('@/views/futicai/kuadu')
const FtcKuaiXuanPan = () => import('@/views/futicai/kuaixuanpan')
const FtcQiTa = () => import('@/views/futicai/qita')
const FtcSanZiDingWei = () => import('@/views/futicai/sanzidingwei')
const FtcSanZiHeShu = () => import('@/views/futicai/sanziheshu')
const FtcSanZiZuHe = () => import('@/views/futicai/sanzizuhe')
const FtcYiZiDingWei = () => import('@/views/futicai/yizidingwei')
const FtcYiZiGuoGuan = () => import('@/views/futicai/yiziguoguan')
const FtcYiZiZuHe = () => import('@/views/futicai/yizizuhe')
const FtcZuXuanLiu = () => import('@/views/futicai/zuxuanliu')
const FtcZuXuanSan = () => import('@/views/futicai/zuxuansan')
//公用
const UserPass = () => import('@/views/pass')
const GameRule = () => import('@/views/rule')
const UserBet = () => import('@/views/bet')
const GameReport = () => import('@/views/report')
const GameResult = () => import('@/views/result')
const UserBackwater = () => import('@/views/backwater')
const UserLog = () => import('@/views/log')
const GameNotice = () => import('@/views/notice')
const GameNoticeDetail = () => import('@/views/notice/detail')
const Search = () => import('@/views/login/search')
const routes = [
  //六合彩
  {
    path: '/liuhecai',
    component: Layout,
    children: [
      { path: '/liuhecai', component: LiuHeTeMa, name: "tema", meta: { auth: true } },
      { path: '/liuhecai/zhengma', component: LiuHeZhengMa, name: "zhengma", meta: { auth: true } },
      { path: '/liuhecai/zhengtema', component: LiuHeZhengTMa, name: "zhengtema", meta: { auth: true } },
      { path: '/liuhecai/lianma', component: LiuHeLianMa, name: "lianma", meta: { auth: true } },
      { path: '/liuhecai/temaxiangmu', component: LiuHeTMXiangmu, name: "temaxiangmu", meta: { auth: true } },
      { path: '/liuhecai/shengxiaoxiangmu', component: LiuHeSXXiangmu, name: "shengxiaoxiangmu", meta: { auth: true } },
      { path: '/liuhecai/zongshuxiangmu', component: LiuHeZSXiangmu, name: "zongshuxiangmu", meta: { auth: true } },
      { path: '/liuhecai/liuxiao', component: LiuHeLiuXiao, name: "liuxiao", meta: { auth: true } },
      { path: '/liuhecai/duoxuanbuzhong', component: LiuHeDXBuzhong, name: "duoxuanbuzhong", meta: { auth: true } },
      { path: '/liuhecai/shengxiaolian', component: LiuHeSXLian, name: "shengxiaolian", meta: { auth: true } },
      { path: '/liuhecai/weishulian', component: LiuHeWSLian, name: "weishulian", meta: { auth: true } },
      { path: '/liuhecai/duoxuanzhongyi', component: LiuHeDXZhongyi, name: "duoxuanzhongyi", meta: { auth: true } },
      { path: '/liuhecai/tepingzhong', component: LiuHeTPZhong, name: "tepingzhong", meta: { auth: true } },
    ]
  },
  //福/體彩
  {
    path: '/futicai',
    component: Layout,
    children: [
      { path: '/futicai', component: FtcShuangMianPanKou, name: "shuangmianpankou", meta: { auth: true } },
      { path: '/futicai/yizizuhe', component: FtcYiZiZuHe, name: "yizizuhe", meta: { auth: true } },
      { path: '/futicai/yizidingwei', component: FtcYiZiDingWei, name: "yizidingwei", meta: { auth: true } },
      { path: '/futicai/erzizuhe', component: FtcErZiZuHe, name: "erzizuhe", meta: { auth: true } },
      { path: '/futicai/erzidingwei', component: FtcErZiDingWei, name: "erzidingwei", meta: { auth: true } },
      { path: '/futicai/erziheshu', component: FtcErZiHeShu, name: "erziheshu", meta: { auth: true } },
      { path: '/futicai/sanzizuhe', component: FtcSanZiZuHe, name: "sanzizuhe", meta: { auth: true } },
      { path: '/futicai/sanzidingwei', component: FtcSanZiDingWei, name: "sanzidingwei", meta: { auth: true } },
      { path: '/futicai/sanziheshu', component: FtcSanZiHeShu, name: "sanziheshu", meta: { auth: true } },
      { path: '/futicai/zuxuansan', component: FtcZuXuanSan, name: "zuxuansan", meta: { auth: true } },
      { path: '/futicai/zuxuanliu', component: FtcZuXuanLiu, name: "zuxuanliu", meta: { auth: true } },
      { path: '/futicai/fushizuhe', component: FtcFuShiZuHe, name: "fushizuhe", meta: { auth: true } },
      { path: '/futicai/yiziguoguan', component: FtcYiZiGuoGuan, name: "yiziguoguan", meta: { auth: true } },
      { path: '/futicai/kuadu', component: FtcKuaDu, name: "kuadu", meta: { auth: true } },
      { path: '/futicai/kuaixuanpan', component: FtcKuaiXuanPan, name: "kuaixuanpan", meta: { auth: true } },
      { path: '/futicai/qita', component: FtcQiTa, name: "qita", meta: { auth: true } },
    ]
  },
  //公用
  {
    path: '/common',
    component: Layout,
    children: [
      { path: '/user/pass', component: UserPass, name: "pass", meta: { auth: true,open:false } },
      { path: '/game/rule', component: GameRule, name: "rule", meta: { auth: true,open:false } },
      { path: '/user/bet', component: UserBet, name: "bet", meta: { auth: true } },
      { path: '/game/report', component: GameReport, name: "report", meta: { auth: true } },
      { path: '/game/result', component: GameResult, name: "result", meta: { auth: true,open:false } },
      { path: '/user/backwater', component: UserBackwater, name: "backwater", meta: { auth: true,open:false } },
      { path: '/user/log', component: UserLog, name: "logs", meta: { auth: true,open:false } },
      { path: '/game/notice', component: GameNotice, name: "notice", meta: { auth: true,open:false } },
      { path: '/game/notice/detail', component: GameNoticeDetail, name: "noticeD", meta: { auth: true,open:false } },
    ]
  },
  //登录
  { path: '/login', component: Login, meta: { auth: false } },
  { path: '/search', component: Search, meta: { auth: false } },
  { path: '/', component: Login, meta: { auth: false } }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  // console.log('router to', to,'-----',!store.state.player.token || to.meta.auth)
  // console.log('router from', from)
  if (!store.state.player.token && to.meta.auth) {
    return next('/login?redirectUrl=' + encodeURIComponent(to.fullPath))
  }
  next()
})

export default router
