import { requestAccountInfo, userAccountGameFilter, userModifyLoginPassword, requestAccountLogout, requestLoginRecordPage } from '@/api/player'
// 用户模块
export default {
  namespaced: true,
  state() {
    return {
      // 用户信息
      profile: {
        accountId: null,
        portrait: '',
        nickName: '',
        mobile: '',
        amount: 0,//剩余額度
        totalDeposit: 0,//總額度
        lastLoginIP: '127.0.0.1',
        loginName: '',
        curPlatCode: '0'
      },
      token: "",
      redirectUrl: '/',// 登录后回跳路径
      codeToken:""
    }
  },
  mutations: {
    SET_AUTH_TOKEN(state, token) {
      state.token = token;
    },
    SET_PLAYER_INFO(state, profile) {
      state.profile = profile
    },
    SET_REDIRECT_URL(state, url) {
      state.redirectUrl = url
    },
    SET_CODE_TOKEN(state, token) {
      state.codeToken = token;
    },
  },
  actions: {
    authTokenAction({ commit }, token) {
      commit('SET_AUTH_TOKEN', token)
    },
    playInfoAction({ commit }, info) {
      commit('SET_PLAYER_INFO', info)
    },
    redirectUrlAction({ commit }, url) {
      commit('SET_REDIRECT_URL', url)
    },
    codeTokenAction({ commit }, token) {
      commit('SET_CODE_TOKEN', token)
    },
    /**
     * 
     * @param {获取用户信息} param0 
     * @returns 
     */
    async requestAccountInfoAction({ commit }, data) {
      return await new Promise((resolve, reject) => {
        requestAccountInfo(data).then(res => {
          resolve(res);
        }).catch(error => {
          reject(error)
        })
      });
    },
    /**
     * 
     * @param {获取用户信息} param0 
     * @returns 
     */
    async requestAccountOutAction({ commit }, { }) {
      return await new Promise((resolve, reject) => {
        requestAccountLogout({}).then(res => {
          resolve(res);
        }).catch(error => {
          reject(error)
        })
      });
    },
    /**
    * 修改登录密碼
    * @param {*} param0 
    * @returns 
    */
    async userModifyLoginPasswordAction({ commit }, data) {
      return await new Promise((resolve, reject) => {
        userModifyLoginPassword(data).then(res => {
          resolve(res);
        }).catch(error => {
          reject(error)
        })
      });
    },
    /**
     * 玩家退水資料
     * @param {*} param0 
     * @returns 
     */
    async userAccountGameFilterAction({ commit }, data) {
      return await new Promise((resolve, reject) => {
        userAccountGameFilter(data).then(res => {
          resolve(res);
        }).catch(error => {
          reject(error)
        })
      });
    },
    /**
     * 玩家登錄日誌
     * @param {*} param0 
     * @param {*} query 
     * @returns 
     */
    async requestLoginRecordPageAction({ commit }, query) {
      return await new Promise((resolve, reject) => {
        requestLoginRecordPage(query).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      });
    },
  }
}
