import { createVNode, render } from 'vue-demi'
import XtxAlert from './xtx-alert.vue'



// 返回的是promise對象，点取消销毁組件，点確認销毁組件
export default ({ title, text }) => {
  const div = document.createElement('div')
  div.setAttribute('class', 'xtx-confirm-container')
  document.body.appendChild(div)
  return new Promise((resolve, reject) => {
    // 点击確認触发的函數
    const submitCallback = () => {
      render(null, div)
      resolve()
    }

    const vn = createVNode(XtxAlert, { title, text, submitCallback })
    render(vn, div)
  })
}
