import request from '@/utils/request'

/**
 * 帐號登录
 * @param {String} loginName - 用户名
 * @param {String} password - 密碼
 * @returns Promise
 */
export const requestAccountLogin = ({ loginName, password }) => {
  return request('/player/login', 'post', { loginName, password, loginType: 2 })
}
/**
 * 账號登出
 * @param {*} param0 
 * @returns 
 */
export const requestAccountLogout = ({ }) => {
  return request('/player/logout', 'post', {})
}
/**
 * 获取信息
 * @param {*} param0 
 * @returns 
 */
export const requestAccountInfo = (data) => {
  return request('/player/info', 'get', data)
}


/**
 * 短信登录
 * @param {String} mobile - 手机號
 * @param {String} smsCode - 验证碼
 * @returns Promise
 */
export const userMobileLogin = ({ mobile, smsCode }) => {
  return request('/player/login', 'post', { mobile, smsCode, loginType: 1 })
}
/**
 * 修改登录密碼
 * @param {*} data 
 * @returns 
 */
export const userModifyLoginPassword = (data) => {
  return request('/player/modify/password', 'post', data)
}

/**
 * 首次登陸修改登陸密碼
 * @param {*} data 
 * @returns 
 */
 export const userRechangeLoginPassword = (data) => {
  return request('/player/rechange/password', 'post', data)
}


/**
 * 获取短信登录验证碼
 * @param {String} mobile - 手机號
 * @returns Promise
 */
export const userMobileLoginMsg = (mobile) => {
  return request('/login/code', 'get', { mobile })
}

/**
 * 校验用户是否存在
 * @param {String} account - 帐號
 * @returns promise
 */
export const userAccountCheck = (account) => {
  return request('/register/check', 'get', { account })
}

/**
 * 玩家退水資料
 * @param {String} account - 帐號
 * @returns promise
 */
export const userAccountGameFilter = (data) => {
  return request('/player/filter', 'get', data)
}

/**
 * 登錄日誌
 * @param {Object} form - 参考接口文档
 */
 export const requestLoginRecordPage = (form) => {
  return request('/player/login/record/page', 'post', form)
}

export const checkSearchCode = (data) => {
  return request('/player/checkSearchCode', 'post', data)
}

export const checkCode = (query) => {
  return request('/player/checkCode', 'post', query)
}