import { createApp } from 'vue'
import App from './App.vue'
// import App from './知识点补充/vue动画.vue'
import router from './router'
import store from './store'
// 导入自己UI组件库
import UI from '@/components/library'
// 1. 重置样式的库
import 'normalize.css'
// 2. 自己項目的重置样式和公用样式
import '@/assets/styles/index.less'
// 创建一个vue应用实例
const app = createApp(App)
  .use(store)
  .use(router)
  .use(UI)
  .mount('#app');