import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// 三个模块
import app from './modules/app'
import player from './modules/player'
import game from './modules/game'
// 创建vuex仓库并导出
export default createStore({
  state: {},
  // 改數據函數
  mutations: {},
  // 请求數據函數
  actions: {},
  // 分模块
  modules: {
    app,
    player,
    game
  },
  // vuex的计算属性
  getters: {},
  // 配置插件
  plugins: [
    createPersistedState({
      key: 'hk-lottery-store',
      paths: ['player', 'app', 'game']
    })
  ]
})
