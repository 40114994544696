<template>
  <div class="xtx-confirm" :class="{ fade }">
    <div class="wrapper" :class="{ fade }">
      <div class="header">
        <h3>{{ title }}</h3>
        <a @click="cancel" href="JavaScript:;" class="iconfont icon-close-new"></a>
      </div>
      <div class="body">
        <i class="iconfont icon-warning"></i>
        <span style="color:red;">{{ text }}</span>
      </div>
      <div class="footer">
        <XtxButton @click="submit" size="mini" type="primary">確認</XtxButton>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import XtxButton from "./xtx-button";
export default {
  name: "XtxAlert",
  components: { XtxButton },
  props: {
    title: {
      type: String,
      default: "重要提示"
    },
    text: {
      type: String,
      default: ""
    },
    cancelCallback: {
      type: Function
    },
    submitCallback: {
      type: Function
    }
  },
  setup(props) {
    // 對话框默认隐藏
    const fade = ref(false);
    // 組件渲染完毕后
    onMounted(() => {
      // 过渡效果需要在元素创建完毕后延時一会加上才会触发
      setTimeout(() => {
        fade.value = true;
      }, 0);
    });
    // 確認
    const submit = () => {
      // 其他事情
      props.submitCallback();
    };
    return { submit, fade };
  }
};
</script>
<style scoped lang="less">
.xtx-confirm {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 8888;
  background: rgba(0, 0, 0, 0);
  &.fade {
    transition: all 0.4s;
    background: rgba(0, 0, 0, 0.5);
  }
  .wrapper {
    width: 400px;
    background: #fff;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: hidden;
    transform: translate(-50%, -60%);
    opacity: 0;
    &.fade {
      transition: all 0.4s;
      transform: translate(-50%, -50%);
      opacity: 1;
    }
    .header,
    .footer {
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
    }
    .body {
      padding: 20px 40px;
      font-size: 16px;
      .icon-warning {
        color: @priceColor;
        margin-right: 3px;
        font-size: 16px;
      }
    }
    .footer {
      text-align: right;
      .xtx-button {
        margin-left: 20px;
      }
    }
    .header {
      position: relative;
      h3 {
        font-weight: normal;
        font-size: 18px;
      }
      a {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 20px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        color: #999;
        &:hover {
          color: #666;
        }
      }
    }
  }
}
</style>
